import { Label, Input } from "@windmill/react-ui";

export const IssuerInfoInvoiceForm = ({ formData, handleChange }) => {
  return (
    <>
      <div>
        <Label htmlFor="invoiceIssuerName">Full name: * </Label>
        <Input
          type="text"
          id="invoiceIssuerName"
          name="invoiceIssuerName"
          value={formData.invoiceIssuerName}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="invoiceIssuerEmail">Email Address: *</Label>
        <Input
          type="email"
          id="invoiceIssuerEmail"
          name="invoiceIssuerEmail"
          value={formData.invoiceIssuerEmail}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="invoiceIssuerAddressLine1">Address Line 1: *</Label>
        <Input
          type="text"
          id="invoiceIssuerAddressLine1"
          name="invoiceIssuerAddressLine1"
          value={formData.invoiceIssuerAddressLine1}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="invoiceIssuerAddressLine2">Address Line 2: </Label>
        <Input
          type="text"
          id="invoiceIssuerAddressLine2"
          name="invoiceIssuerAddressLine2"
          value={formData.invoiceIssuerAddressLine2}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="invoiceIssuerCity">City: *</Label>
        <Input
          type="text"
          id="invoiceIssuerCity"
          name="invoiceIssuerCity"
          value={formData.invoiceIssuerCity}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="invoiceIssuerPostcode">Postcode: *</Label>
        <Input
          type="text"
          id="invoiceIssuerPostcode"
          name="invoiceIssuerPostcode"
          value={formData.invoiceIssuerPostcode}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="invoiceIssuerCountry">Country: *</Label>
        <Input
          type="text"
          id="invoiceIssuerCountry"
          name="invoiceIssuerCountry"
          value={formData.invoiceIssuerCountry}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="invoiceIssuerAccountName">Account Name: *</Label>
        <Input
          type="text"
          id="text"
          name="invoiceIssuerAccountName"
          value={formData.invoiceIssuerAccountName}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="invoiceIssuerSortCode">Sort Code: *</Label>
        <Input
          type="text"
          id="text"
          name="invoiceIssuerSortCode"
          value={formData.invoiceIssuerSortCode}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="invoiceIssuerAccountNumber">Account Number: *</Label>
        <Input
          type="number"
          id="text"
          name="invoiceIssuerAccountNumber"
          value={formData.invoiceIssuerAccountNumber}
          onChange={handleChange}
          required
        />
      </div>
    </>
  );
};
