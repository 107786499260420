import { useState } from "react";
import dayjs from "dayjs";
import { IoCloudUploadOutline } from "react-icons/io5";
import { Controller, useForm } from "react-hook-form";
import Papa from "papaparse";
import { Button, Card, CardBody } from "@windmill/react-ui";

//internal import
import PageTitle from "components/Typography/PageTitle";
import { notifyError, notifySuccess } from "utils/toast";
import spinnerLoadingImage from "assets/img/spinner.gif";
import { postRequest } from "../../helpers/requests";

const ResultsUpload = () => {
  const [loadingUpload, setLoadingUpload] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [fileData, setFileData] = useState([]);

  const preStyle = {
    whiteSpace: "pre-wrap", // Wraps the text and preserves whitespace and line breaks
    wordBreak: "break-word", // Ensures that words break correctly at the end of the line
    // overflowWrap: "break-word", // Allows unbreakable words to be broken
  };

  const handleFileChange = (file) => {
    if (file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      if (fileType === "csv") {
        parseCSV(file);
      } else {
        alert("Unsupported file type. Please upload either a CSV or XLSX file.");
      }
    }
  };

  const parseCSV = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      transform: (value, columnName) => {
        if (columnName.toLowerCase().includes("date")) {
          // Attempt to parse the date using multiple formats
          const parsedDate = dayjs(value, ["DD/MM/YYYY", "DD/MM/YY", "YYYY-MM-DD"]);
          return parsedDate.isValid() ? parsedDate.format("DD/MM/YYYY") : value;
        }
        return value;
      },
      complete: (results) => {
        setFileData(results.data);
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };

  const onSubmit = async () => {
    setLoadingUpload(true);

    // Process or send data to the server
    const response = await postRequest("labs/TDL/results", fileData);
    if (response.status === "success") {
      notifySuccess("Successfully requested");
      setLoadingUpload(false);
    } else {
      const errorMessage = response.message || "Failed to send result emails";
      notifyError(errorMessage);
    }
  };

  return (
    <>
      <PageTitle>{"Upload test results and send email"}</PageTitle>
      <Card className="min-w-0 shadow-xs overflow-hidden bg-white dark:bg-gray-800 mb-5">
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Upload a CSV File</h1>
            <Controller
              name="file"
              control={control}
              defaultValue={[]}
              rules={{ required: true }}
              render={({ field }) => (
                <input
                  type="file"
                  accept=".csv"
                  onChange={(e) => {
                    field.onChange(e);
                    handleFileChange(e.target.files[0]);
                  }}
                />
              )}
            />
            {errors.file && <p>File is required.</p>}

            {loadingUpload ? (
              <Button disabled={true} type="button" className=" mt-5 h-12 w-full">
                <img src={spinnerLoadingImage} alt="Loading" width={20} height={10} />{" "}
                <span className="font-serif ml-2 font-light">Processing</span>
              </Button>
            ) : (
              <button
                disabled={!fileData}
                type="submit"
                className={`${
                  !fileData && "opacity-50 cursor-not-allowed bg-red-300"
                } flex items-center justify-center text-sm leading-5 h-12 mt-5 w-full text-center transition-colors duration-150 font-medium focus:outline-none px-6 py-2 rounded-md text-white bg-green-500 border border-transparent active:bg-green-600 hover:bg-green-600 focus:ring focus:ring-purple-300`}
              >
                Submit
                <span className="ml-2 text-base">
                  <IoCloudUploadOutline />
                </span>
              </button>
            )}
          </form>
        </CardBody>
      </Card>
      <Card className="min-w-0 shadow-xs overflow-hidden bg-white dark:bg-gray-800 mb-5">
        <CardBody>
          <h2>
            <b>File preview</b>
          </h2>
          <pre style={preStyle}>{fileData && fileData.length > 0 ? JSON.stringify(fileData, null, 2) : null}</pre>
        </CardBody>
      </Card>
    </>
  );
};

export default ResultsUpload;
