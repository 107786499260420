import React, { useState } from "react";
import { TextField, Button, Autocomplete, Box, Typography, Grid, Tabs, Tab, Modal } from "@mui/material";
import { getRequest, postRequest } from "../../../helpers/requests";
import { notifyError, notifySuccess } from "../../../utils/toast";
import "./availability.css";

const OpeningHoursForm = ({ onSuccess, closeForm, isOpen }) => {
  const [doctors, setDoctors] = useState([]);
  const [clinicians, setClinicians] = useState([]);
  const [bookingEntities, setBookingEntities] = useState([]);
  const [locations, setLocations] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedBookingEntity, setSelectedBookingEntity] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedClinician, setSelectedClinician] = useState(null);

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedBookingEntity(null);
    setSelectedLocation(null);
    setSelectedDoctor(null);
    setSelectedClinician(null);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setEndDate("");
    setStartTime("");
    setEndTime("");
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleClinicianChange = (event, value) => {
    setSelectedClinician(value ? value.user_id : null);
    setSelectedLocation(4); // Value 4 for home visit
  };

  const handleDoctorChange = (event, value) => {
    setSelectedDoctor(value ? value.user_id : null);
    setSelectedLocation(5); // Value 5 for virtual
  };

  const handleCreateAvailability = async () => {
    try {
      const payload = [
        {
          startDate,
          endDate,
          startTime,
          endTime,
          locationID: selectedLocation,
          doctorID: selectedDoctor,
          clinicianID: selectedClinician,
          bookingEntityID: selectedBookingEntity || null,
        },
      ];
      const response = await postRequest("bookings/availability", payload);

      if (response) {
        notifySuccess("Availability created");
        onSuccess();
      }
    } catch (error) {
      notifyError("Failed to create availability");
    }
  };

  const handleCancel = () => {
    if (closeForm) closeForm();
  };

  const fetchBookingEntities = async () => {
    const response = await getRequest("entities/list");
    if (response) {
      setBookingEntities(response.entities);
    } else {
      const errorMessage = response.message || "Failed to retrieve booking entities";
      notifyError(errorMessage);
    }
  };

  const getBookingEntityLocation = async () => {
    if (!selectedBookingEntity) {
      // If selectedBookingEntity is null or undefined, exit early.
      return;
    }
    try {
      const fetchedLocations = await getRequest(`locations/entity/${selectedBookingEntity}?limit=100`);
      if (fetchedLocations) {
        setLocations(fetchedLocations.data);
      }
    } catch (error) {
      notifyError("Failed to fetch locations");
    }
  };

  const fetchDoctors = async () => {
    try {
      const fetchDoctors = await getRequest("/bookings/service/doctor");
      if (Array.isArray(fetchDoctors)) {
        setDoctors(fetchDoctors);
      }
    } catch (error) {
      notifyError("Failed to fetch doctors");
    }
  };

  const fetchClinicianList = async () => {
    try {
      const response = await getRequest("users/clinicians");
      if (response) {
        setClinicians(response.clinician);
      }
    } catch (error) {
      notifyError("Failed to fetch clinicians");
    }
  };

  return (
    <Modal open={isOpen} onClose={handleCancel}>
      <Box
        sx={{
          maxWidth: 600,
          margin: "auto",
          padding: 3,
          backgroundColor: "#f9f9f9",
          borderRadius: 2,
          boxShadow: 3,
          mt: 10,
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
          Create Availability
        </Typography>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Booking Entity" />
          <Tab label="Doctor" />
          <Tab label="Clinician" />
        </Tabs>
        {selectedTab === 0 && (
          <>
            <Autocomplete
              options={bookingEntities}
              getOptionLabel={(option) => option.display_name}
              onOpen={fetchBookingEntities}
              onChange={(event, value) => {
                setSelectedBookingEntity(value ? value.booking_entity_id : null);
                if (value) getBookingEntityLocation();
              }}
              renderInput={(params) => (
                <TextField {...params} label="Booking Entities" variant="filled" margin="normal" fullWidth />
              )}
            />
            <Autocomplete
              options={locations}
              getOptionLabel={(option) => option.location}
              onOpen={() => selectedBookingEntity && getBookingEntityLocation()}
              onChange={(event, value) => setSelectedLocation(value ? value.location_id : null)}
              renderInput={(params) => (
                <TextField {...params} label="Location" variant="filled" margin="normal" fullWidth />
              )}
            />
          </>
        )}
        {selectedTab === 1 && (
          <Autocomplete
            options={doctors}
            getOptionLabel={(option) =>
              `${option.metadata.personal_title} ${option.metadata.first_name} ${option.metadata.last_name}`
            }
            onOpen={fetchDoctors}
            onChange={handleDoctorChange}
            renderInput={(params) => (
              <TextField {...params} label="Doctor" variant="filled" margin="normal" fullWidth />
            )}
          />
        )}
        {selectedTab === 2 && (
          <Autocomplete
            options={clinicians}
            getOptionLabel={(option) =>
              option.full_name ? option.full_name : `${option.first_name} ${option.last_name}`
            }
            onOpen={fetchClinicianList}
            onChange={handleClinicianChange}
            renderInput={(params) => (
              <TextField {...params} label="Nurses" variant="filled" margin="normal" fullWidth />
            )}
          />
        )}
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              Starting
            </Typography>
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              Ending
            </Typography>
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Start Time"
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="End Time"
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              fullWidth
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button onClick={handleCancel} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleCreateAvailability}>
            Create Availability
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default OpeningHoursForm;
