import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Select,
  MenuItem,
  ListItemText,
  FormControl,
  InputLabel,
  Chip,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { getRequest, postRequest } from "../../helpers/requests";
import { notifyError, notifySuccess } from "../../utils/toast";

const ServiceCreateModal = ({ open, handleClose, bookingEntityId }) => {
  const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const currentUser = JSON.parse(localStorage.getItem("user_metadata"));

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const allLocations = await getRequest(`locations?entity=${bookingEntityId}&limit=100`);
        setAvailableLocations(allLocations.locations || []);
      } catch (error) {
        console.error("Failed to fetch locations:", error);
      }
    };
    fetchLocations();
  }, [bookingEntityId]);

  const handleServiceTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedServiceTypes(typeof value === "string" ? value.split(",") : value);
  };

  const handleTagDelete = (tagToDelete) => () => {
    setTags((tags) => tags.filter((tag) => tag !== tagToDelete));
  };

  const handleTagAdd = (event) => {
    if (event.key === "Enter" && event.target.value) {
      event.preventDefault();
      setTags((prevTags) => [...prevTags, event.target.value]);
      event.target.value = "";
    }
  };

  const handleLocationChange = (event) => {
    const {
      target: { value },
    } = event;
    const updatedLocations = value.map((locationId) => {
      return { locationId }; // Store the selected location ID in object format
    });
    setSelectedLocations(updatedLocations); // Update state with selected locations
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      service: {
        serviceName: "",
        serviceType: selectedServiceTypes,
        description: "",
        apptDuration: 15,
        apptStackable: true,
        servicePrice: 0,
        serviceStatus: true,
        discountEnabled: true,
        private: true,
        serviceCode: "",
        serviceIcon: "health_screening",
        bullets: "",
        firstDescription: "",
        descriptionContinued: "",
        displayOrder: null,
        emailTemplate: "",
        questionnaireLink: null,
        tags: tags,
        disclaimers: null,
        bookable: true,
      },
      locations: [],
      entity: {
        entityId: bookingEntityId,
        entityName: "",
      },
    },
  });

  const onSubmit = async (data) => {
    // Split bullets by new lines and trim any extra spaces
    const bulletsArray = data.service.bullets ? data.service.bullets.split("\n").map((bullet) => bullet.trim()) : [];
    const bulletsString = "['" + bulletsArray.join("', '") + "']";

    // Similarly, format firstDescription, descriptionContinued, and disclaimers
    const firstDescriptionArray = data.service.firstDescription
      ? data.service.firstDescription.split("\n").map((item) => item.trim())
      : [];
    const firstDescriptionString = "['" + firstDescriptionArray.join("', '") + "']";

    const descriptionContinuedArray = data.service.descriptionContinued
      ? data.service.descriptionContinued.split("\n").map((item) => item.trim())
      : [];
    const descriptionContinuedString = "['" + descriptionContinuedArray.join("', '") + "']";

    const disclaimersArray = data.service.disclaimers
      ? data.service.disclaimers.split("\n").map((item) => item.trim())
      : [];
    const disclaimersString = "['" + disclaimersArray.join("', '") + "']";

    // Serialize bullets array to JSON string
    const payload = {
      draftType: "service",
      action: "CREATE",
      draft: [
        {
          service: {
            ...data.service,
            serviceStatus: String(data.service.serviceStatus),
            servicePrice: Number(data.service.servicePrice) || 0,
            serviceType: selectedServiceTypes.join(", "),
            tags: tags.join(", "),

            // Store bullets as JSON string
            bullets: bulletsString,

            description: String(data.service.description),
            // Format the new fields as strings that look like arrays
            firstDescription: firstDescriptionString,
            descriptionContinued: descriptionContinuedString,
            disclaimers: disclaimersString,
          },
          locations: selectedLocations,
          entity: data.entity,
        },
      ],
      bookingEntityId: bookingEntityId,
      createdBy: currentUser ? currentUser.username : null,
    };
    const response = await postRequest("drafts/create", payload);
    if (response.status === "success") {
      notifySuccess("Service creation submitted successfully - Pending Approval");
      handleClose();
    } else {
      const errorMessage = response.message || "Failed to create service";
      notifyError(errorMessage);
    }
  };

  const renderSelectedLocation = (selected) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      {selected.map((locationId) => {
        const location = availableLocations.find((loc) => loc.location_id === locationId);
        return (
          <Chip
            key={locationId}
            label={location ? (location.street ? `${location.street}, ${location.city}` : location.city) : locationId}
          />
        );
      })}
    </Box>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
          width: {
            xs: "90%",
            sm: "90%",
            md: "90%",
            lg: "1200px",
            xl: "1200px",
          },
          maxWidth: "100%",
          maxHeight: "90vh",
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom sx={{ fontWeight: 600 }}>
          Create Service
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Controller
                name="service.serviceStatus"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Service Active"
                    helperText="Check if the service is currently active"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="service.discountEnabled"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Discount Allowed"
                    helperText="Check if discounts are allowed for this service"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="service.apptStackable"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Appointment Stackable"
                    helperText="Check if appointments for this service can be stacked in the same timeslot"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="service.bookable"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Bookable"
                    helperText="Check if this service can be booked"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="service.serviceName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Service Name *"
                    error={!!errors.serviceName}
                    helperText={errors.serviceName ? "Service Name is required" : "The name of the service"}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="service.serviceType"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="service-type-label">Service Type</InputLabel>
                    <Select
                      {...field}
                      multiple
                      value={selectedServiceTypes}
                      onChange={handleServiceTypeChange}
                      label="Service Type"
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} sx={{ mb: 1 }} />
                          ))}
                        </Box>
                      )}
                    >
                      {["In-Clinic", "Virtual", "Home Visit", "Postal"].map((type) => (
                        <MenuItem key={type} value={type}>
                          <Checkbox checked={selectedServiceTypes.indexOf(type) > -1} />
                          <ListItemText primary={type} />
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="caption" color="textSecondary">
                      Select one or more service types
                    </Typography>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="service.description"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Preview Description *"
                    multiline
                    rows={4}
                    error={!!errors.description}
                    helperText={errors.description ? "Description is required" : "Shown to users on the list page"}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="service.servicePrice"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Service Price *"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    }}
                    error={!!errors.servicePrice}
                    helperText={
                      errors.servicePrice
                        ? "Service Price is required"
                        : "The price in pounds and pence, for example £129.99"
                    }
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="service.apptDuration"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Appointment Duration *"
                    error={!!errors.apptDuration}
                    helperText={
                      errors.apptDuration
                        ? "Appointment Duration is required"
                        : "Enter the duration of the appointment in minutes"
                    }
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="service.serviceCode"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Service Code *"
                    error={!!errors.serviceCode}
                    helperText={
                      errors.serviceCode
                        ? "Service Code is required"
                        : "This must be a unique code in camelCase of the service name (no spaces)"
                    }
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="service.serviceIcon"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Service Icon Path *"
                    helperText={
                      errors.serviceIcon
                        ? "Service Icon is required"
                        : "The file name of an existing icon - use 'health_screening' for a generic icon"
                    }
                  />
                )}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="service.firstDescription"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="First Description"
                    multiline
                    rows={4}
                    helperText="Shown on the service pop-up when a user selects the service"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="service.bullets"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Bullets"
                    multiline
                    rows={4}
                    helperText="Shown after the description on the service pop-up"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="service.descriptionContinued"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Description Continued"
                    multiline
                    rows={4}
                    helperText="Optionally show a second paragraph after the bullets on the service pop-up"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="service.displayOrder"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="number"
                    label="Display Order"
                    helperText="Override with a high number than other entities, or leave empty for default order"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="service.emailTemplate"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Email Template"
                    helperText="Enter the email template (Ask devs if unsure)"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="service.questionnaireLink"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Questionnaire Link"
                    helperText="Optionally enter a link if this service does not go to checkout"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Tags"
                helperText="Press Enter after each tag you input. For example, Wellbeing (enter) International Treatments (enter)."
                onKeyDown={handleTagAdd}
              />
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 1 }}>
                {tags.map((tag) => (
                  <Chip key={tag} label={tag} onDelete={handleTagDelete(tag)} />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="service.disclaimers"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Disclaimers"
                    multiline
                    rows={4}
                    helperText="Disclaimers are shown in a smaller font at the end of the service pop-up"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="associated-locations-label">Select locations that provide this service</InputLabel>
                <Select
                  labelId="associated-locations-label"
                  multiple
                  value={selectedLocations.map((loc) => loc.locationId)}
                  onChange={handleLocationChange}
                  renderValue={renderSelectedLocation}
                >
                  {availableLocations.map((location) => (
                    <MenuItem key={location.location_id} value={location.location_id}>
                      <Checkbox checked={selectedLocations.some((loc) => loc.locationId === location.location_id)} />
                      <ListItemText
                        primary={location.street ? `${location.street}, ${location.city}` : location.city}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <Button onClick={handleClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Create Service
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default ServiceCreateModal;
