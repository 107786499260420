import {
  FiArrowUpCircle,
  FiAward,
  FiBox,
  FiCreditCard,
  FiCalendar,
  FiFileText,
  FiGlobe,
  FiGrid,
  FiInfo,
  FiStar,
  FiUserPlus,
  FiUsers,
} from "react-icons/fi";
import GroupsIcon from "@mui/icons-material/Groups";

import { MdOutlineEventAvailable } from "react-icons/md";

/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */
const sidebar = [
  {
    path: "/", // the url
    icon: FiGrid, // icon
    name: "Home", // name that appear in Sidebar
    description: "Sirkka Health", // description of the route
  },
  {
    path: "/dashboard",
    icon: FiGrid,
    name: "Home",
    description: "Sirkka Health",
  },
  {
    path: "/booking-entities",
    icon: GroupsIcon,
    name: "Booking Entities",
    description: "See all Booking Entities",
  },
  {
    path: "/booking-entity",
    icon: FiArrowUpCircle,
    name: "Booking Entity",
    description: "Your Booking Entity",
  },
  {
    path: "/customers",
    icon: FiUsers,
    name: "Customers",
    description: "Manage customer data",
  },
  {
    path: "/customers/corporate",
    icon: FiAward,
    name: "Corporate",
    description: "Manage corporate data",
  },
  {
    path: "/memberships",
    icon: FiStar,
    name: "Memberships",
    description: "Manage membership data",
  },
  {
    path: "/availability",
    icon: MdOutlineEventAvailable,
    name: "Availabilities",
    description: "Manage location availability",
  },
  {
    path: "/availability/clinician",
    icon: MdOutlineEventAvailable,
    name: "Clinician",
    description: "Manage your clinician availability",
  },
  {
    path: "/bookings/appointments",
    icon: FiCalendar,
    name: "Appointments",
    description: "Manage appointment data",
  },
  {
    path: "/postals",
    icon: FiBox,
    name: "Postals",
    description: "Manage postal",
  },
  {
    path: "/documents",
    icon: FiFileText,
    name: "Documents",
    description: "Manage customer documents",
  },
  {
    path: "/booking-users",
    icon: FiUserPlus,
    name: "Booking users",
    description: "Manage booking users",
  },
  // {
  //   path: "/create-service",
  //   icon: FiBriefcase,
  //   name: "Create Services",
  //   description: "Create new service",
  // },
  {
    path: "/create-group-booking",
    icon: FiArrowUpCircle,
    name: "Generate Test Requests",
    description: "Create test request forms for group bookings",
  },
  {
    path: "/upload-results",
    icon: FiArrowUpCircle,
    name: "Send Test Results",
    description: "Send test results to customers",
  },
  {
    path: "/drafts",
    icon: FiGlobe,
    name: "Approve Draft Listings",
    description: "Manage pre-approved listing contents",
  },
  {
    path: "/invoices",
    icon: FiCreditCard,
    name: "Create invoices",
    description: "Manage and create invoices",
  },
  {
    path: "/faqs",
    icon: FiInfo,
    name: "FAQs",
    description: "Need help? Read our frequently asked questions",
  },

  // {
  //   icon: FiSlack,
  //   name: "Catalog",
  //   routes: [
  //     {
  //       path: "/products",
  //       name: "Products",
  //     },
  //     {
  //       path: "/categories",
  //       name: "Categories",
  //     },
  //     {
  //       path: "/attributes",
  //       name: "Attributes",
  //     },
  //     {
  //       path: "/coupons",
  //       name: "Coupons",
  //     },
  //   ],
  // },
  // {
  //   path: '/our-staff',
  //   icon: FiUser,
  //   name: 'Our Staff',
  // },
  // {
  //   icon: FiGlobe,
  //   name: "International",
  //   routes: [
  //     {
  //       path: "/languages",
  //       name: "Languages",
  //     },
  //     {
  //       path: "/currencies",
  //       name: "Currencies",
  //     },
  //   ],
  // },
  // {
  //   path: '/setting',
  //   icon: FiSettings,
  //   name: 'Setting',
  // },
  // {
  //   icon: FiTarget,
  //   name: "ViewStore",
  //   path: "http://localhost:3000",
  //   outside: "store",
  // },

  // {
  //   icon: FiSlack,
  //   name: "Pages",
  //   routes: [
  //     // submenu

  //     {
  //       path: "/404",
  //       name: "404",
  //     },
  //     {
  //       path: "/coming-soon",
  //       name: "Coming Soon",
  //     },
  //   ],
  // },
];

export default sidebar;
