const apiUrl = process.env.REACT_APP_API_URL || "/api";
const separator = apiUrl.charAt(0) !== "/" ? "/" : "";
const fullAPIURL = (endpoint) => apiUrl + separator + endpoint;

const makeQueryParams = (queryParams) => {
  const queryArray = Object.keys(queryParams);

  if (queryArray.length) {
    queryArray.forEach((key) => queryParams[key] === undefined && delete queryParams[key]);
  }

  const params = new URLSearchParams(queryParams);
  return params.toString();
};

const getRequest = async (url, queryParams = {}) => {
  url = fullAPIURL(url);

  const query = makeQueryParams(queryParams);
  if (query) {
    url = `${url}?${query}`;
  }

  return await fetch(url)
    .then((response) => {
      // handle the response
      return response.json();
    })
    .catch((error) => {
      // handle the error
      console.log(error);
      return error.message;
    });
};

const getRequestWithHeaders = async (url, queryParams = {}) => {
  url = fullAPIURL(url);

  const query = makeQueryParams(queryParams);
  if (query) {
    url = `${url}?${query}`;
  }

  return await fetch(url)
    .then((response) =>
      response.json().then((data) => ({
        data,
        headers: response.headers,
      })),
    )
    .catch((error) => {
      // handle the error
      console.log(error);
      return error.message;
    });
};

const patchRequest = async (url, data) => {
  url = fullAPIURL(url);

  const fetchOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return await fetch(url, fetchOptions)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      // handle the error
      console.log(error);
      return error.message;
    });
};

const postRequest = async (url, data, headers = null, options = { parseResponse: true }) => {
  url = fullAPIURL(url);

  const fetchOptions = {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  if (headers === "form-data") {
    fetchOptions.body = data;
    delete fetchOptions.headers;
  }

  try {
    const response = await fetch(url, fetchOptions);

    // If options specify to parse the response, return response.json() (default behavior)
    if (options.parseResponse) {
      return await response.json();
    }

    // Return the full response object (for blob or other formats)
    return response;
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
};

const deleteRequest = async (url, queryParams = {}) => {
  url = fullAPIURL(url);

  const query = makeQueryParams(queryParams);
  if (query) {
    url = `${url}?${query}`;
  }

  const fetchOptions = {
    method: "DELETE",
  };

  return await fetch(url, fetchOptions)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
      return error.message;
    });
};

export { apiUrl, getRequest, getRequestWithHeaders, postRequest, patchRequest, deleteRequest };
