import { Label, Input } from "@windmill/react-ui";

export const CustomInvoiceForm = ({ formData, handleChange }) => {
  return (
    <>
      <div>
        <Label htmlFor="invoiceServiceName">Service Name: </Label>
        <Input
          type="text"
          id="invoiceServiceName"
          name="invoiceServiceName"
          value={formData.invoiceServiceName}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="invoiceCustomPricing">Agreed Custom Price: </Label>
        <Input
          type="number"
          id="invoiceCustomPricing"
          name="invoiceCustomPricing"
          value={formData.invoiceCustomPricing}
          onChange={handleChange}
        />
      </div>
    </>
  );
};
