import { useState, useRef } from "react";
import PageTitle from "components/Typography/PageTitle";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { Button, CardBody } from "@windmill/react-ui";
import { GroupTestingInvoiceForm } from "components/form/invoice/GroupTestingInvoice";
import { HomeVisitInvoiceForm } from "components/form/invoice/HomeVisitInvoice";
import { CustomInvoiceForm } from "components/form/invoice/CustomInvoice";
import { IssuerInfoInvoiceForm } from "components/form/invoice/IssuerInfoInvoice";
import { notifyError, notifySuccess } from "../../utils/toast";
import { postRequest } from "../../helpers/requests";

function CreateInvoiceForm() {
  const initialFormData = {
    invoiceIssuerName: "",
    invoiceIssuerEmail: "",
    invoiceIssuerAddressLine1: "",
    invoiceIssuerAddressLine2: "",
    invoiceIssuerCity: "",
    invoiceIssuerPostcode: "",
    invoiceIssuerCountry: "",
    invoiceIssuerAccountName: "",
    invoiceIssuerSortCode: "",
    invoiceIssuerAccountNumber: "",
    invoiceServiceName: "",
    invoiceHourlyRate: "",
    invoiceTotalHours: "",
    invoiceTotalMiles: "",
    invoiceBaseRate: "",
    invoicePatientNumber: "",
    invoiceAdditionalTestNumber: "",
    invoiceAdditionalTestCharge: "",
    invoiceCentrifugeNumber: "",
    invoiceCustomPricing: "",
    invoiceType: "",
  };
  const [openCardIndex, setOpenCardIndex] = useState(null);
  const [submitIssuerInfoButton, setSubmitIssuerInfoButton] = useState("Save");
  const [formData, setFormData] = useState(initialFormData);

  const handleCardToggle = (index) => {
    setOpenCardIndex(openCardIndex === index ? null : index);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmitIssuerInfo = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    notifySuccess("Personal information saved successfully");
    setSubmitIssuerInfoButton("Update");
  };

  const handleSubmit = (event, type) => {
    event.preventDefault();
    notifySuccess("Please wait while we create your invoice...", 1000);
    const updatedFormData = {
      ...formData,
      invoiceType: type,
    };

    setFormData(updatedFormData);
    submitForm(updatedFormData);
  };

  const submitForm = async (formData) => {
    try {
      const response = await postRequest("invoices/create", formData, null, { parseResponse: false });

      if (!response || !response.ok) {
        notifyError("Invoice creation failed, please try again!");
        return;
      }
      setFormData(initialFormData);
      const blob = await response.blob();
      const file = window.URL.createObjectURL(blob);
      window.open(file);
    } catch (error) {
      console.log(error);
      notifyError("Invoice creation failed, please try again!");
    }
  };
  return (
    <>
      <PageTitle>{"Create PDF Invoices"}</PageTitle>
      <Card>
        <CardBody>
          <p>
            IMPORTANT:
            <br></br>
            1. Please put the amount that you have agreed with Sirkka staff in the relevant invoice section. Sirkka
            staff follow the contracts that you have signed and/or apply discretions on a case by case basis.
            <br></br>
            2. Please ensure that you have completed the{" "}
            <a
              href="https://forms.office.com/pages/responsepage.aspx?id=teK2BhsA9kafJYS0elBAJATRdceoBrJMkG8eAcguCzhUOUtZMjdNS0tOVFQ0Q0FNN05GN0RHRk1ROS4u"
              target="_blank"
            >
              <b>Phlebotemist Post-Session Feedback Form</b>
            </a>{" "}
            before submitting your invoice. We cannot process your invoice before receiving the completed Phlebotemist
            Post-Session Feedback form.
            <br></br>
            3. The invoice has 3 sections: Individual phlebotomy, Group Phlebotomy, Customised Fee. Please fill out the
            appropriate form with the correct information, click the generate pdf invoice button. A pdf of the invoice
            should appear in a new tab on your browser. Please check it carefully and email a copy to{" "}
            <b>accounts@sirkkaltd.com</b>.
          </p>
        </CardBody>
      </Card>
      <br />
      <Card>
        <CardHeader title="Personal Details" subheader="Please fill in your details here and save." />
        <CardBody>
          <form id="personal-details" onSubmit={handleSubmitIssuerInfo}>
            <div className="grid gap-4 lg:gap-6 xl:gap-6 lg:grid-cols-3 xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 py-2">
              <IssuerInfoInvoiceForm formData={formData} handleChange={handleChange} />
              <Button type="submit">{submitIssuerInfoButton}</Button>
            </div>
          </form>
        </CardBody>
      </Card>
      <br />
      <Card className="min-w-0 shadow-xs overflow-hidden bg-white dark:bg-gray-800 mb-5">
        <CardHeader
          title="Form for phlobotemy group testing (paid by hourly rate)"
          subheader="A testing involving equal to and greater than 4 patients counts as group testing"
          action={
            <IconButton onClick={() => handleCardToggle(0)} aria-label="expand" size="small">
              {openCardIndex === 0 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        ></CardHeader>
        <div style={{ backgroundColor: "rgba(211,211,211,0.4)" }}>
          <Collapse in={openCardIndex === 0} timeout="auto" unmountOnExit>
            <CardBody>
              <form id="group-testing-form" onSubmit={(e) => handleSubmit(e, "hourly")}>
                <div className="grid gap-4 lg:gap-6 xl:gap-6 lg:grid-cols-3 xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 py-2">
                  <GroupTestingInvoiceForm formData={formData} handleChange={handleChange} />
                  <Button type="submit">Generate Invoice PDF</Button>
                </div>
              </form>
            </CardBody>
          </Collapse>
        </div>
      </Card>
      <Card className="min-w-0 shadow-xs overflow-hidden bg-white dark:bg-gray-800 mb-5">
        <CardHeader
          title="Form for phlobotemy/ECG/other individual testing (paid by number of patients)"
          subheader="This is for non-group testing, 1 to 3 patients in a testing session."
          action={
            <IconButton onClick={() => handleCardToggle(1)} aria-label="expand" size="small">
              {openCardIndex === 1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        ></CardHeader>
        <div style={{ backgroundColor: "rgba(211,211,211,0.4)" }}>
          <Collapse in={openCardIndex === 1} timeout="auto" unmountOnExit>
            <CardBody>
              <form id="individual-form" onSubmit={(e) => handleSubmit(e, "quantity")}>
                <div className="grid gap-4 lg:gap-6 xl:gap-6 lg:grid-cols-3 xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 py-2">
                  <HomeVisitInvoiceForm formData={formData} handleChange={handleChange} />
                  <Button type="submit">Generate Invoice PDF</Button>
                </div>
              </form>
              <br></br>
            </CardBody>
          </Collapse>
        </div>
      </Card>
      <Card className="min-w-0 shadow-xs overflow-hidden bg-white dark:bg-gray-800 mb-5">
        <CardHeader
          title="Form for custom payment (custom payment discussed with our commercial team)"
          subheader=""
          action={
            <IconButton onClick={() => handleCardToggle(2)} aria-label="expand" size="small">
              {openCardIndex === 2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        ></CardHeader>
        <div style={{ backgroundColor: "rgba(211,211,211,0.4)" }}>
          <Collapse in={openCardIndex === 2} timeout="auto" unmountOnExit>
            <CardBody>
              <form id="custom-invoice" onSubmit={(e) => handleSubmit(e, "custom")}>
                <div className="grid gap-4 lg:gap-6 xl:gap-6 lg:grid-cols-3 xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 py-2">
                  <CustomInvoiceForm formData={formData} handleChange={handleChange} />
                  <Button type="submit">Generate Invoice PDF</Button>
                </div>
              </form>
              <br></br>
            </CardBody>
          </Collapse>
        </div>
      </Card>
      <br />
    </>
  );
}

export default CreateInvoiceForm;
