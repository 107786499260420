import { Label, Input } from "@windmill/react-ui";

export const GroupTestingInvoiceForm = ({ formData, handleChange }) => {
  return (
    <>
      <div>
        <Label htmlFor="invoiceServiceName">Service Name: </Label>
        <Input
          type="text"
          id="invoiceServiceName"
          name="invoiceServiceName"
          value={formData.invoiceServiceName}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="invoiceHourlyRate">Hourly Rate: </Label>
        <Input
          type="number"
          id="invoiceHourlyRate"
          name="invoiceHourlyRate"
          value={formData.invoiceHourlyRate}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="invoiceTotalHours">Total Hours: </Label>
        <Input
          type="number"
          id="invoiceTotalHours"
          name="invoiceTotalHours"
          value={formData.invoiceTotalHours}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="invoiceTotalMiles">Total Miles: </Label>
        <Input
          type="number"
          id="invoiceTotalMiles"
          name="invoiceTotalMiles"
          value={formData.invoiceTotalMiles}
          onChange={handleChange}
        />
      </div>
    </>
  );
};
