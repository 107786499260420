import { Label, Input, Select } from "@windmill/react-ui";

export const HomeVisitInvoiceForm = ({ formData, handleChange }) => {
  return (
    <>
      <div>
        <Label htmlFor="invoiceServiceName">Service Name: </Label>
        <Input
          type="text"
          id="invoiceServiceName"
          name="invoiceServiceName"
          value={formData.invoiceServiceName}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="invoiceBaseRate">Base rate for a single test: </Label>
        <Input
          type="number"
          id="invoiceBaseRate"
          name="invoiceBaseRate"
          value={formData.invoiceBaseRate}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="invoicePatientNumber">Number of people (up to 3): </Label>
        <Select
          type="text"
          id="invoicePatientNumber"
          name="invoicePatientNumber"
          value={formData.invoicePatientNumber}
          onChange={handleChange}
        >
          <option value="">Select number</option> {/* Default option */}
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </Select>
      </div>
      <div>
        <Label htmlFor="invoiceAdditionalTestNumber">Number of additional tests: </Label>
        <Select
          type="text"
          id="invoiceAdditionalTestNumber"
          name="invoiceAdditionalTestNumber"
          value={formData.invoiceAdditionalTestNumber}
          onChange={handleChange}
        >
          <option value="">Select number</option> {/* Default option */}
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </Select>
      </div>
      <div>
        <Label htmlFor="invoiceAdditionalTestCharge">Charge per additional test: </Label>
        <Input
          type="number"
          id="invoiceAdditionalTestCharge"
          name="invoiceAdditionalTestCharge"
          value={formData.invoiceAdditionalTestCharge}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="invoiceCentrifugeNumber">Centrifuges (Up to 3): </Label>
        <Input
          type="number"
          id="invoiceCentrifugeNumber"
          name="invoiceCentrifugeNumber"
          value={formData.invoiceCentrifugeNumber}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="invoiceTotalMiles">Total Miles: </Label>
        <Input
          type="number"
          id="invoiceTotalMiles"
          name="invoiceTotalMiles"
          value={formData.invoiceTotalMiles}
          onChange={handleChange}
        />
      </div>
    </>
  );
};
